import { PhoneNumberUtil } from 'google-libphonenumber';
import { getCountry } from "react-international-phone";

const phoneUtil = PhoneNumberUtil.getInstance();

export const getCountryForNumber = (phone: string) => {
  try {
    const countryCode = phoneUtil.parseAndKeepRawInput(phone).getCountryCode();

    if (countryCode === undefined) return null;

    return getCountry({ field: "dialCode", value: `${countryCode}` })?.name ?? null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    console.error(error);
    return false;
  }
};

export default isPhoneValid;
