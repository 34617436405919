import { AxiosError } from "axios";
import postNewUserData from "src/services.ts";
import { trackFieldError, trackFormError, trackFormSuccess } from "src/segment";
import { EMAIL_PROVIDER_FORBIDDEN } from "src/literals.ts";
import type { Errors, FormState } from "src/types";

export default async function postData(userData: FormState): Promise<{ ok: boolean; errors: Errors | null }> {
  try {
    await postNewUserData(userData);

    trackFormSuccess(userData);

    return { ok: true, errors: null };
  } catch (error) {
    const errorObject = error as AxiosError<{ message: string }>;
    const status = errorObject?.response?.status;

    switch (true) {
      case status === 401: {
        console.error("error 401");

        const errorMessage = EMAIL_PROVIDER_FORBIDDEN;
        trackFieldError({ inputName: "Email", inputValue: userData?.email || "", errorMessage });

        return { ok: false, errors: { email: errorMessage } as Errors };
      }
      case status === 409 && !!errorObject.response?.data?.message: {
        console.error("error 409");

        const errorMessage = errorObject.response.data.message;
        trackFieldError({ inputName: "Email", inputValue: userData?.email || "", errorMessage });

        return { ok: false, errors: { email: errorMessage } as Errors };
      }
      default: {
        console.error("error default");

        trackFormError(userData);
        throw { axiosError: error, userData };
      }
    }
  }
}
