export const PRIMARY_PRODUCTS = [
  {
    id: 5,
    name: "Gasoline",
  },
  {
    id: 19,
    name: "Diesel / Jet",
  },
  {
    id: 6,
    name: "Naphtha",
  },
  {
    id: 7,
    name: "LPG",
  },
  {
    id: 14,
    name: "Crude",
  },
  {
    id: 13,
    name: "Fuel Oil",
  },
];

export const FREIGHT_PRODUCTS = [
  {
    id: 8,
    name: "Clean",
  },
  {
    id: 22,
    name: "Dirty",
  },
];

export const ROLES = [
  {
    id: 9,
    name: "Physical Trader",
    primaryProducts: PRIMARY_PRODUCTS,
  },
  {
    id: 10,
    name: "Paper Trader",
    primaryProducts: PRIMARY_PRODUCTS,
  },
  {
    id: 11,
    name: "Oil Broker",
    primaryProducts: PRIMARY_PRODUCTS,
  },
  {
    id: 12,
    name: "Trading Manager",
    primaryProducts: PRIMARY_PRODUCTS,
  },
  {
    id: 13,
    name: "Originator",
    primaryProducts: PRIMARY_PRODUCTS,
  },
  {
    id: 14,
    name: "Ship Broker",
    primaryProducts: FREIGHT_PRODUCTS,
  },
  {
    id: 15,
    name: "Ship Charterer",
    primaryProducts: FREIGHT_PRODUCTS,
  },
  {
    id: 16,
    name: "Ship Owner",
    primaryProducts: FREIGHT_PRODUCTS,
  },
];

export const REGIONS = ["APAC", "Africa", "Asia", "Europe", "Middle East", "North America", "South America"];
