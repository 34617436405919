import { REGIONS } from "src/data";
import type { LoaderFunctionArgs } from "react-router-dom";
import type { FormState } from "src/types";

const INITIAL_STATE: FormState = {
  firstName: null,
  lastName: null,
  email: null,
  phoneNumber: null,
  companyName: null,
  region: null,
  userRole: "12",
  primaryProduct: "14",
  subscribeNewsletter: false,
  termsAndConditions: false,
};

export function getDefaultDataFromQueryParams(request: LoaderFunctionArgs["request"]): FormState {
  // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams#examples
  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);

  const valuesFromEntries = Object.fromEntries<string | null>(searchParams.entries());

  const { region, subscribeNewsletter, termsAndConditions, ...validEntries } = valuesFromEntries;

  const regionIndex = REGIONS.findIndex((r) => region && r.toLowerCase() === region.toLowerCase());

  return {
    ...INITIAL_STATE,
    subscribeNewsletter: subscribeNewsletter === "true",
    termsAndConditions: termsAndConditions === "true",
    ...validEntries,
    region: regionIndex > -1 ? REGIONS[regionIndex] : null,
  };
}
