import { ForwardedRef, useEffect, useRef, useState } from "react";
import Input, { InputProps } from "../Input/Input";
import useCombinedRefs from "src/hooks/useCombinedRefs";
import styles from "./Select.css";
import clsx from "clsx";
import { Icon, IconType } from "@spartacommodities/sparta-react-core-components";
import { theme } from "src/theme";

type SelectProps<ValueType> = {
  classes?: {
    root?: string;
    input?: InputProps["classes"];
  };
  computeLabel?: (value: ValueType) => string;
  options?: ValueType[];
  onChange?: (value: ValueType) => void;
  ref?: ForwardedRef<HTMLInputElement>;
  value?: ValueType;
} & Omit<InputProps, "ref" | "onChange" | "value" | "defaultValue" | "autoComplete" | "classes">;

const defaultComputeLabel = (value: unknown) => String(value);

const Select = <ValueType,>({
  computeLabel = defaultComputeLabel,
  classes,
  options = [],
  ref: forwardedRef,
  value,
  onChange,
  ...rest
}: SelectProps<ValueType>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<typeof value>(value);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const ref = useCombinedRefs(forwardedRef);

  useEffect(() => {
    const blurHandler = (ev: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(ev.target as Node)) {
        setIsOpen(false);
      }
    };
    const focusHandler = () => setIsOpen(true);

    window.addEventListener("click", blurHandler);

    const current = ref.current;
    current?.addEventListener("focus", focusHandler);

    return () => {
      window.removeEventListener("click", blurHandler);
      current?.removeEventListener("focus", focusHandler);
    };
  }, []);

  const handleOnClickOption = (option: ValueType) => {
    onChange?.(option);
    setIsOpen(false);
    setSelected(option);
  };

  const selectedOptionLabel = selected !== undefined ? computeLabel(selected) : "";

  return (
    <div className={styles.root} ref={wrapperRef}>
      <Input
        autoComplete="off"
        classes={{
          ...classes?.input,
          root: clsx(styles.input, classes?.input?.root),
        }}
        ref={ref}
        value={selectedOptionLabel}
        {...rest}
      >
        <div className={styles.icon}>
          <Icon
            type={isOpen ? IconType.ARROW_UP : IconType.ARROW_DOWN}
            fill={theme.colors.neutral.dark_45}
            height={24}
            width={24}
          />
        </div>
      </Input>
      {isOpen && (
        <ul className={styles.list}>
          {options.map((option) => {
            const optionLabel = computeLabel(option);

            return (
              <li
                className={clsx(styles.option, selectedOptionLabel === optionLabel && styles.selected)}
                key={optionLabel}
                onClick={() => handleOnClickOption(option)}
              >
                {optionLabel}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

Select.displayName = "Select";

export default Select;
