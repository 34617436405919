import { useRef } from "react";
import type { PropsWithChildren } from "react";
import * as segment from "src/segment/handlers.ts";

export function SegmentPageView({ children }: PropsWithChildren) {
  const executed = useRef(false);

  if (!executed.current) {
    segment.pageView();
    executed.current = true;
  }

  return children;
}
