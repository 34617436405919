import clsx from "clsx";
import StatusPanel, { StatusPanelProps } from "../StatusPanel/StatusPanel";
import { successMessage } from "./StatusMessage.css";
import { link, primaryLink } from "src/styles.css";
import { SPARTA_LINK_MESSAGE, SPARTA_MAIL_LINK } from "src/literals";

type StatusMessageProps = Omit<StatusPanelProps, 'children'>;

export const StatusMessage = ({ icon, title, subtitle, buttonText, onClick, description }: StatusMessageProps) => (
  <StatusPanel icon={icon} title={title} subtitle={subtitle} buttonText={buttonText} onClick={onClick}>
    <p className={successMessage}>
      {description}
      <a className={clsx(link, primaryLink)} href={SPARTA_MAIL_LINK} target="_blank" rel="noreferrer">
        {SPARTA_LINK_MESSAGE}
      </a>
    </p>
  </StatusPanel>
);
