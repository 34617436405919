import clsx from "clsx";
import type { ComponentProps, FC } from "react";
import { StatusMessage } from "src/components/StatusMessage/StatusMessage.tsx";
import { IconType } from "@spartacommodities/sparta-react-core-components";
import { SUCCCESS_DESCRIPTION, SUCCESS_BUTTON, SUCCESS_SUBTITLE, SUCCESS_TITLE } from "src/literals.ts";
import { trackGoToLoginClicked } from "src/segment";
import { resultContainer, signupContainer } from "../signUp/SignUp.css.ts";

const { VITE_SPARTA_APP } = import.meta.env;

const Success: FC<Partial<ComponentProps<typeof StatusMessage>>> = ({
  icon = IconType.CIRCLED_CHECK,
  title = SUCCESS_TITLE,
  subtitle = SUCCESS_SUBTITLE,
  description = SUCCCESS_DESCRIPTION,
  buttonText = SUCCESS_BUTTON,
  ...rest
}) => {
  const handleOnClick = () => {
    trackGoToLoginClicked();
    window.open(VITE_SPARTA_APP, "_blank");
  };
  return (
    <div className={clsx(signupContainer, resultContainer)}>
      <StatusMessage
        icon={icon}
        title={title}
        subtitle={subtitle}
        description={description}
        buttonText={buttonText}
        onClick={handleOnClick}
        {...rest}
      />
    </div>
  );
};

export default Success;
