import getRoleAndPrimaryProduct from "src/utils/getRoleAndPrimaryProduct.ts";
import { getCountryForNumber } from "src/utils/phoneValidaton.ts";
import type { FormState } from "../types";

export type InputName =
  | "First Name"
  | "Last Name"
  | "Email"
  | "Region"
  | "Country"
  | "Mobile"
  | "Company Name"
  | "Role"
  | "Primary product";

export type CheckboxName = "Newsletter" | "T&Cs";

export function getSignUpFormInputValues(data: FormState): string {
  const { role, primaryProduct } = getRoleAndPrimaryProduct(data);

  const orderedFields: Array<[InputName | CheckboxName, unknown]> = [
    ["First Name", data.firstName ?? ""],
    ["Last Name", data.lastName ?? ""],
    ["Email", data.email ?? ""],
    ["Region", data.region ?? ""],
    ["Country", getCountryForNumber(data.phoneNumber ?? "") ?? ""],
    ["Mobile", data.phoneNumber ?? ""],
    ["Company Name", data.companyName ?? ""],
    ["Role", role?.name || ""],
    ["Primary product", primaryProduct?.name ?? ""],
    ["Newsletter", data.subscribeNewsletter ? "Y" : "N"],
    ["T&Cs", data.termsAndConditions ? "Y" : "N"],
  ];

  return `(${orderedFields.map(({ 1: value }) => value ?? "").join(", ")})`;
}
