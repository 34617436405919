import axios from "axios";
import type { FormState } from "./types";

const { VITE_APP_API_URL } = import.meta.env;

const endpoint = "/auth/v2/user_signup";

const HEADERS = {
  headers: {
    "Content-Type": "application/json",
  },
};

export default async function postNewUserData(userData: FormState) {
  try {
    const url = `${VITE_APP_API_URL}${endpoint}`;
    return await axios.post(url, userData, HEADERS);
  } catch (error) {
    console.log("There was an error signing up the user", error);
    throw error;
  }
}
