import { forwardRef, useImperativeHandle, useRef } from "react";
import styles from "./PopUp.css";
import { Icon, IconType } from "@spartacommodities/sparta-react-core-components";
import { theme } from "src/theme";

type Props = {
  children: React.ReactNode;
  title: string;
};

const PopUp = forwardRef<HTMLDialogElement, Props>(({ children, title }, ref) => {
  const innerRef = useRef<HTMLDialogElement>(null);

  useImperativeHandle(ref, () => innerRef.current as HTMLDialogElement, [innerRef]);

  const detectBackdropClick = ({ currentTarget, clientX, clientY }: React.MouseEvent<HTMLDialogElement>) => {
    const { bottom, left, right, top } = currentTarget.getBoundingClientRect();

    if (left > clientX || right < clientX || top > clientY || bottom < clientY) {
      innerRef.current?.close();
    }
  };

  return (
    <dialog className={styles.root} onClick={detectBackdropClick} ref={innerRef}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h3>{title}</h3>
          <button className={styles.close} onClick={() => innerRef.current?.close()}>
            <Icon type={IconType.CLOSE} width={32} height={32} fill={theme.colors.neutral.dark_45} />
          </button>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </dialog>
  );
});

PopUp.displayName = "PopUp";

export default PopUp;
