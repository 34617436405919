import { headerContainer, logo } from "./Header.css";

export default function Header() {
  return (
    <header className={headerContainer}>
      <div className={logo}>
        <img alt="Sparta logo" src="/svgs/expanded-logo.svg" />
      </div>
    </header>
  );
}
