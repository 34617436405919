import { EMAIL_ERROR } from "src/literals.ts";
import { trackFieldError } from "src/segment";
import type { Errors, FormState } from "src/types";
import { validateEmail } from "./utils.ts";

export default function validateForm(formData: FormData) {
  const email = formData.get("email") as string;
  const isValidEmail = validateEmail(email);

  if (!isValidEmail) {
    const newErrors: Errors = {
      email: EMAIL_ERROR,
    };

    if (newErrors.email) trackFieldError({ inputName: "Email", inputValue: email || "", errorMessage: EMAIL_ERROR });

    return { errors: newErrors, formState: undefined };
  }

  // Why is this necessary?
  // When we transform the data from the form, we are using the return value from the checkboxes. When the value is not
  // defined, it is set to "on".
  //
  // Even though the value is not defined, it is still a "string | number | readonly string[] | undefined", so we need
  // to convert it to a boolean.
  const formState = {
    ...Object.fromEntries(formData),
    subscribeNewsletter: formData.get("subscribeNewsletter") === "on",
    termsAndConditions: formData.get("termsAndConditions") === "on",
  } as unknown as FormState;

  return { errors: undefined, formState };
}
