import { AnalyticsBrowser, EventProperties } from "@segment/analytics-next";

const { MODE, VITE_APP_SEGMENT_WRITE_KEY } = import.meta.env;
const isProdEnv = MODE === "production";

let analytics: AnalyticsBrowser | undefined;

export const init = () => {
  if (!isProdEnv) return;

  analytics = AnalyticsBrowser.load({ writeKey: VITE_APP_SEGMENT_WRITE_KEY });
};

export const track = (name: string, rawPayload?: EventProperties) => {
  const payload = {
    ...rawPayload,
    environment: MODE,
  };

  if (!isProdEnv)
    return console.log(`Environment not enabled. Would be logged "${name}" event with this payload:`, payload);

  if (!analytics) return console.error("Segment not initialized");

  analytics?.track(name, payload);
};

export const pageView = () => {
  if (!isProdEnv) return console.log('Environment not enabled. Would be logged "Loaded a page" event');

  if (!analytics) return console.error("Segment not initialized");

  analytics?.page();
};
