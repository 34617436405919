import React, { ChangeEventHandler, forwardRef, useState } from "react";
import styles from "./Input.css";
import clsx from "clsx";
import useCombinedRefs from "src/hooks/useCombinedRefs";

export enum InputStatus {
  DEFAULT = "default",
  ACTIVE = "active",
  ERROR = "error",
  MODIFIED = "modified",
}

export type InputProps = Omit<React.ComponentPropsWithoutRef<"input">, 'className'> & {
  classes?: {
    root?: string;
    input?: string;
    placeholder?: string;
    helperText?: string;
  };
  status?: InputStatus;
  helperText?: string;
};

const computeDefaultValue = (value: InputProps["value"]): NonNullable<InputProps["defaultValue"]> => {
  if (typeof value === "number") {
    return 0;
  }

  if (Array.isArray(value)) {
    return [];
  }

  return "";
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { children, classes, defaultValue, disabled, helperText, onChange: onChangeProp, placeholder, status = InputStatus.DEFAULT, value: valueProp, ...rest },
    forwardedRef
  ) => {
    const ref = useCombinedRefs(forwardedRef);

    const isControlled = valueProp !== undefined;
    const hasDefaultValue = defaultValue !== undefined;

    const [internalValue, setInternalValue] = useState(hasDefaultValue ? defaultValue : computeDefaultValue(valueProp));

    const value = isControlled ? valueProp : internalValue;

    const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChangeProp?.(e);

      if (!isControlled) {
        setInternalValue(e.target.value);
      }
    };

    return (
      <div className={clsx(styles.root, classes?.root, disabled && styles.disabled, helperText && styles.hasHelperText)}>
        <input
          className={clsx(styles.input, styles[status], classes?.input)}
          disabled={disabled}
          onChange={handleOnChange}
          ref={ref}
          value={value}
          placeholder=" "
          {...rest}
        />
        {placeholder && <label className={clsx(styles.placeholder, classes?.placeholder)} htmlFor={rest.id} title={placeholder} />}
        {helperText && <span className={clsx(styles.helperText, styles[status], classes?.helperText)}>{helperText}</span>}
        {children}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
