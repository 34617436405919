import { checkbox, input, text } from "./Checkbox.css.ts";

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  children?: JSX.Element;
};

export default function Checkbox({ label, children, ...rest }: CheckboxProps) {
  return (
    <div className={input}>
      <input
        {...rest}
        type="checkbox"
        className={checkbox}
      />
      {label && <label className={text}>{label}</label>}
      {children}
    </div>
  );
}
