import axios, { AxiosRequestConfig } from "axios";

const fetcher = <TResponse>(url: string, config: AxiosRequestConfig = {}): Promise<TResponse> => {
  return axios
    .get<TResponse>(url, config)
    .then((res) => res.data)
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};

export default fetcher;
