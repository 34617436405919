import type { FormState } from "../types";
import { track } from "./handlers";

import { getSignUpFormInputValues } from "./data";
import type { CheckboxName, InputName } from "./data";

// When the user clicks on any of the input fields or the dropdown selectors of the sign-up form to fill it
export const trackFieldClicked = (inputName: InputName) =>
  track("Market Commentaries Sign Up Field Form Clicked", { inputName });

// When the user selects a value for any of the dropdown selectors or removes the focus on any of the input fields of the sign-up form
export const trackFieldFilled = (payload: { inputName: InputName; inputValue: string }) =>
  track("Market Commentaries Sign Up Field Form Filled", payload);

// When the user checks any of the checkboxes of the sign-up form
export const trackFieldChecked = (inputName: CheckboxName) =>
  track("Market Commentaries Sign Up Field Form Checked", { inputName });

// When the user checks any of the checkboxes of the sign-up form
export const trackFieldUnchecked = (inputName: CheckboxName) =>
  track("Market Commentaries Sign Up Field Form Unchecked", { inputName });

// When the user clicks on the Privacy Policy link to see it
export const trackOpenPrivacyPolicy = () => track("Market Commentaries Sign Up Privacy Policy Clicked");

// When the user clicks on the T&Cs link to see it
export const trackOpenTermsAndConditions = () => track("Market Commentaries Sign Up T&Cs Clicked");

// When the user clicks on the Sign-up button to send the form
export const trackSubmitForm = (payload: FormState) => {
  const inputValues = getSignUpFormInputValues(payload);

  return track("Market Commentaries Sign Up Button Clicked", { inputValues });
};

// When we show an error in any of the form fields
export const trackFieldError = (payload: { inputName: InputName; inputValue: string; errorMessage: string }) =>
  track("Market Commentaries Sign Up Field Error", payload);

// When the account creation went well, and we show the pop-up with the confirmation in the screen
export const trackFormSuccess = (payload: FormState) => {
  const inputValues = getSignUpFormInputValues(payload);

  track("Market Commentaries Sign Up Registration Ok", { inputValues });
};

// When the user clicks on the Go-to Login button in the pop-up with the confirmation
export const trackGoToLoginClicked = () => track("Market Commentaries Sign Up Go Login Clicked");

// When an error occurred during the account creation, and we show the pop-up with the error in the screen
export const trackFormError = (payload: FormState) => {
  const inputValues = getSignUpFormInputValues(payload);

  track("Market Commentaries Sign Up Registration Error", { inputValues });
};

// When the user clicks on the "Try it again" button in the pop-up with the error
export const trackTryAgainClicked = () => track("Market Commentaries Sign Up Try Again Clicked");
