import { datadogRum } from "@datadog/browser-rum";

export function datadogRumInit() {
  const {
    VITE_APP_DATADOG_APPLICATION_ID,
    VITE_APP_DATADOG_CLIENT_TOKEN,
    VITE_APP_DATADOG_SITE,
    MODE,
    VITE_APP_VERSION,
  } = import.meta.env;

  if (!MODE) return;
  if (!VITE_APP_DATADOG_APPLICATION_ID) return;
  if (!VITE_APP_DATADOG_CLIENT_TOKEN) return;
  if (!VITE_APP_DATADOG_SITE) return;

  if (MODE === "staging" || MODE === "production") {
    datadogRum.setTrackingConsent("granted");
    datadogRum.init({
      applicationId: VITE_APP_DATADOG_APPLICATION_ID,
      clientToken: VITE_APP_DATADOG_CLIENT_TOKEN,
      site: VITE_APP_DATADOG_SITE,
      service: "sparta-signup",
      env: MODE === "production" ? "prod" : "staging",
      // Specify a version number to identify the deployed version of your application in Datadog
      version: VITE_APP_VERSION || "dev",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
  }
}

export function setDatadogUserInfo(email: string)  {
  datadogRum.setUser({
    email: email,
  });
}

export function datadogRumEnd() {
  datadogRum.setTrackingConsent("not-granted");
}
