import { ROLES } from "src/data";
import type { FormState } from "../types";

export default function getRoleAndPrimaryProduct(formState: FormState) {
  const selectedRole = ROLES.find((role) => role.id === Number.parseInt(formState?.userRole || "", 10));

  const selectPrimaryProduct = selectedRole?.primaryProducts.find(
    (product) => product.id === Number.parseInt(formState?.primaryProduct || "", 10)
  );

  return {
    role: selectedRole,
    primaryProduct: selectPrimaryProduct,
  };
}
