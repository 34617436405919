import React from "react";
import { trackFieldChecked, trackFieldFilled, trackFieldUnchecked, trackOpenTermsAndConditions } from "src/segment";
import type { FormState } from "src/types";

export const trackSelectChange = <PropertyName extends keyof Pick<FormState, "region">>({
  name,
}: {
  value: FormState[PropertyName];
  name: string;
}) => {
  return trackFieldFilled({ inputName: "Region", inputValue: name || "" });
};

export const trackCheckboxChange = (property: keyof Pick<FormState, "subscribeNewsletter" | "termsAndConditions">) => {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const track = checked ? trackFieldChecked : trackFieldUnchecked;

    switch (property) {
      case "subscribeNewsletter":
        track("Newsletter");
        break;
      case "termsAndConditions":
        track("T&Cs");
        break;
    }
  };
};

export const trackOnClickTermsAndConditions = (popUpRef: React.RefObject<HTMLDialogElement>) => {
  trackOpenTermsAndConditions();
  popUpRef.current?.showModal();
};
