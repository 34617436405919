import clsx from "clsx";
import type { ComponentProps, FC } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { StatusMessage } from "src/components/StatusMessage/StatusMessage.tsx";
import { IconType } from "@spartacommodities/sparta-react-core-components";
import { ERROR_BUTTON, ERROR_DESCRIPTION, ERROR_SUBTITLE, ERROR_TITLE } from "src/literals.ts";
import { trackTryAgainClicked } from "src/segment";
import { resultContainer, signupContainer } from "../signUp/SignUp.css.ts";

const Error: FC<Partial<ComponentProps<typeof StatusMessage>>> = ({
  icon = IconType.CIRCLED_INFO_ROUND,
  title = ERROR_TITLE,
  subtitle = ERROR_SUBTITLE,
  description = ERROR_DESCRIPTION,
  buttonText = ERROR_BUTTON,
  ...rest
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleOnClick = () => {
    trackTryAgainClicked();
    navigate({ pathname: "/", search: searchParams.toString() });
  };

  return (
    <div className={clsx(signupContainer, resultContainer)}>
      <section className={resultContainer}>
        <StatusMessage
          icon={icon}
          title={title}
          subtitle={subtitle}
          description={description}
          buttonText={buttonText}
          onClick={handleOnClick}
          {...rest}
        />
      </section>
    </div>
  );
};

export default Error;
