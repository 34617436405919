import React, { FC } from "react";
import { Icon, IconType, theme } from "@spartacommodities/sparta-react-core-components";
import clsx from "clsx";
import {
  actionButton,
  descriptionContainer,
  statusContent,
  statusContainer,
  titleContainer,
  iconContainer,
} from "./StatusPanel.css";
import { button, primaryButton } from "src/styles.css";

export type StatusPanelProps = {
  icon: IconType;
  title: string;
  subtitle?: string;
  description?: string;
  buttonText?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
};

const StatusPanel: FC<StatusPanelProps> = ({ icon, title, subtitle, description, buttonText, onClick, children }) => (
  <div className={statusContainer}>
    <div className={iconContainer}>
      <Icon type={icon} width={46} height={46} fill={theme.colors.main.primary} />
    </div>
    <div className={statusContent}>
      <div className={titleContainer}>
        <p>{title}</p>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <div className={descriptionContainer}>
        {description && <p className={description}>{description}</p>}
        {children}
      </div>
      {buttonText && onClick && (
        <button className={clsx(button, primaryButton, actionButton)} type="button" onClick={onClick}>
          {buttonText}
        </button>
      )}
    </div>
  </div>
);

export default StatusPanel;
