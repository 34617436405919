import type { FeatureFlags } from "typings/flags";

import { useFlags } from "launchdarkly-react-client-sdk";

export function useFeatureFlags(): FeatureFlags {
  return useFlags<FeatureFlags>();
}

export function useFeatureFlag<Key extends keyof FeatureFlags>(key: Key): FeatureFlags[Key] | null {
  const flags = useFeatureFlags();

  if (flags && key in flags) {
    return flags[key];
  }

  return null;
}
