import styles from "./TermsOfUse.css.ts";

export default function TermsOfUse() {
  return (
    <div className={styles.root}>
      <div>
        <p>
          Where the Subscriber subscribes to receive the Market Outlook Data Service, the following terms shall apply.
          The Subscriber&apos;s continued use, receipt and/or access to the Market Outlook Data shall be deemed receipt
          and acceptance of these Market Outlook Data terms.
        </p>
        <br />
        <b>Definitions:</b>
        <p>For the purpose of these Market Outlook Data Terms, the following words have the meaning given to them:</p>
        <br />
        <p>
          <b>&apos;Authorised User(s)&apos;</b> means the Subscriber and its employees, agents, contractors or advisers
          who are authorised by the Subscriber to use the Market Outlook Data.
        </p>
        <br />
        <p>
          <b>&apos;Intellectual Property Rights&apos;</b> means patents, utility models, rights to inventions, copyright
          and related rights, trade marks and service marks, trade names and domain names, rights in get-up, goodwill
          and the right to sue for passing off or unfair competition, rights in designs, rights in computer software,
          database rights, rights to preserve the confidentiality of information (including know-how and trade secrets)
          and any other intellectual property rights, including all applications for (and rights to apply for and be
          granted), renewals or extensions of, and rights to claim priority from, such rights and all similar or
          equivalent rights or forms of protection which subsist or will subsist, now or in the future, in any part of
          the world.
        </p>
        <br />
        <p>
          <b>&apos;Market Outlook Data&apos;</b> means any data or information made available under the Provider&apos;s
          &apos;Market Outlook&apos; service and as may be set out in an Order Form or otherwise made available by the
          Provider to the Subscriber in connection with that service.
        </p>
        <br />
        <p>
          <b>&apos;Provider&apos;:</b> means Sparta Commodities SA a company incorporated in Switzerland and having its
          registered office at 1, Place des Florentins, 1204 Geneva.
        </p>
        <br />
        <p>
          <b>&apos;Subscriber&apos;:</b> means the entity which has subscribed to receive the Market Outlook Data.
        </p>
      </div>

      <div>
        <ol className={styles.primaryList}>
          <li>
            Subject always to the Subscriber complying with any usage restrictions the Provider notifies to it and
            procuring that all Authorised Users comply with those restrictions, the Provider grants to the Subscriber a
            non-exclusive, non-transferable, revocable licence during the term to access and use the Market Outlook Data
            for its internal business purposes.
          </li>
          <li>
            Except as expressly provided in this Agreement, the Subscriber shall not:
            <ol className={styles.secondaryList}>
              <li>use the Market Outlook Data (wholly or in part) in its products or services;</li>
              <li>redistribute the Market Outlook Data (wholly or in part);</li>
              <li>
                use, disclose, co-mingle, distribute, make available, sell, copy, display, assign, transfer,
                sublicense, lease, furnish, lend, republish, transmit, distribute, alter, modify, adapt, translate,
                disassemble, decompile, prepare or create derivative works (save as permitted by this Agreement), or
                reverse engineer the Market Outlook Data;
              </li>
              <li>
                store, share or copy, in any way, all or any portion of the Market Outlook Data supplied by the
                Provider.
              </li>
            </ol>
          </li>
          <li>
            The Subscriber shall notify the Provider if it becomes aware of any breach of any of these terms or of
            any unauthorised use of any Market Outlook Data and promptly take all reasonable steps to mitigate the
            effects of the breach.
          </li>
          <li>
            The Subscriber acknowledges that (i) all Intellectual Property Rights in the Market Outlook Data and
            connected materials are the property of the Provider or its licensors, as the case may be; (ii) it shall
            have no rights in or to the Market Outlook Data or the connected materials other than the right to use them
            in accordance with the express terms of this Agreement; and (iii) the Provider or its licensors has or have
            made and will continue to make substantial investment in the obtaining, verification, selection,
            co-ordination, development, presentation and supply of the Market Outlook Data; and (iv) goodwill generated
            through the Subscriber&apos;s use of the Market Outlook Data shall belong to the Provider.
          </li>
          <li>
            The Subscriber assigns to the Provider, and shall assign to it, with full title guarantee, all
            Intellectual Property Rights in any manipulated data it may create based on or using the Market Outlook
            Data, by way of future assignment.
          </li>
          <li>
            The Subscriber shall use all reasonable endeavours to procure that any necessary third party shall, at
            the Subscriber&apos;s cost, promptly execute such documents and perform such acts as may reasonably be
            required for the purpose of giving full effect to these terms.
          </li>
          <li>
            Notwithstanding anything to the contrary in these terms or otherwise, the Subscriber is solely
            responsible for any decisions (including trades) made as a result of its use or interpretation of the Market
            Outlook Data. The Provider does not warrant that the Market Outlook Data or its supply will be uninterrupted
            or error free; nor does the Provider make any warranty as to the results that may be obtained from use of
            the Market Outlook Data. Except as expressly set forth in this Annex 2, the Market Outlook Data is provided
            &quot;as is&quot; and the Provider disclaims all warranties, express or implied, conditions or other terms
            including but not limited to, implied warranties, conditions and other terms of merchantability,
            satisfactory quality or fitness for a particular purpose and non-infringement.
          </li>
          <li>
            The Provider may cease, amend or delay the supply of the Market Outlook Data and/or the licence granted
            to the Subscriber under these terms, at any time, in its sole discretion.
          </li>
        </ol>
      </div>
    </div>
  );
}
