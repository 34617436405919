import { ForwardedRef, useImperativeHandle, useRef } from "react";

const useCombinedRefs = <RefType>(forwardRef: ForwardedRef<RefType> | undefined) => {
  const ref = useRef<RefType>(null);

  useImperativeHandle(forwardRef, () => ref.current as RefType, [ref]);

  return ref;
};

export default useCombinedRefs;
